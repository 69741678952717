import luckPanda from "../img/별은과운세보기.png";
import chatPDF from "../img/chatPDF.png";

const Projects = () => {
  return (
    <div class="container">
      <br></br>
      <div
        class="row row-cols-1 row-cols-md-2 g-4"
        style={{ justifyItems: "center", textAlign: "center" }}
      >
        <div class="col">
          <div
            class="card border-dark mb-3"
            style={{ maxWidth: "20rem", marginTop: "10px" }}
          >
            <div class="card-header">별은과 운세보기</div>
            <div class="card-body">
              <h5 class="card-title" style={{ minHeight: "10rem" }}>
                <a href="https://luckpanda.pages.dev/">
                  <img src={luckPanda} class="card-img-top" alt="..." />
                </a>
              </h5>
              <p class="card-text">
                당신의 생년월일과 태어난 시간을 활용해 운세상담을 해드립니다.
                <br></br>
                <span class="badge rounded-pill text-bg-success">chatGPT</span>
              </p>
            </div>
          </div>
        </div>

        <div class="col">
          <div
            class="card border-dark mb-3"
            style={{ maxWidth: "20rem", marginTop: "10px" }}
          >
            <div class="card-header">PDF분석하기</div>
            <div class="card-body">
              <h5 class="card-title" style={{ minHeight: "10rem" }}>
                <a href="https://staris-aipdf.streamlit.app/">
                  <img src={chatPDF} class="card-img-top" alt="..." />
                </a>
              </h5>
              <p class="card-text">
                업로드한 PDF를 분석하여 대화할 수 있습니다.<br></br>
                <span class="badge rounded-pill text-bg-success">
                  chatGPT
                </span>{" "}
                <span class="badge rounded-pill text-bg-success">
                  LangChain
                </span>{" "}
                <span class="badge rounded-pill text-bg-success">LLM</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
