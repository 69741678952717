const Profile = () => {
  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col text-start">
            <br></br>
            안녕하세요. 별은이에요.<br></br> 본 사이트는 React로 제작되었습니다.
            <br></br>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
