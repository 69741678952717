import React, { useState } from "react";
import "./App.css";
import Projects from "./component/Projects";
import Profile from "./component/Profile";
import Challenge from "./component/Chellenge";
import Header from "./component/Header";
import About from "./component/About";

function App() {
  const [page, setPage] = useState("Profile");
  const ProfileClick = () => {
    setPage("Profile");
    document.getElementById("Profile").classList.add("active");
    document.getElementById("Projects").classList.remove("active");
    document.getElementById("Challenge").classList.remove("active");
    console.log(page);
  };
  const ProjectsClick = () => {
    setPage("Projects");
    document.getElementById("Projects").classList.add("active");
    document.getElementById("Profile").classList.remove("active");
    document.getElementById("Challenge").classList.remove("active");
    console.log(page);
  };
  const ChallengeClick = () => {
    setPage("Challenge");
    document.getElementById("Challenge").classList.add("active");
    document.getElementById("Profile").classList.remove("active");
    document.getElementById("Projects").classList.remove("active");
    console.log(page);
  };
  return (
    <div className="App" style={{ margin: "10px" }}>
      <div class="container text-center">
        <div class="row" style={{ justifyContent: "center" }}>
          <div class="col-12 col-md-8">
            <div id="mainContent">
              <Header></Header>
              <About></About>
              <ul class="nav justify-content-center nav-underline nav-fill">
                <li class="nav-item">
                  <button
                    class="nav-link active"
                    id="Profile"
                    aria-current="page"
                    href="#"
                    onClick={ProfileClick}
                    style={{ color: "#805555" }}
                  >
                    Profile
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class="nav-link"
                    id="Projects"
                    href="#"
                    onClick={ProjectsClick}
                    style={{ color: "#805555" }}
                  >
                    Projects
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class="nav-link"
                    href="#"
                    id="Challenge"
                    onClick={ChallengeClick}
                    style={{ color: "#805555" }}
                  >
                    Challenge
                  </button>
                </li>
              </ul>
              {page === "Profile" && <Profile></Profile>}
              {page === "Projects" && <Projects></Projects>}
              {page === "Challenge" && <Challenge></Challenge>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
