const Header = () => {
  return (
    <>
      <button
        style={{ marginLeft: "5px" }}
        class="nav-link active"
        id="nav-home-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-home"
        type="button"
        role="tab"
        aria-controls="nav-home"
        aria-selected="true"
      >
        <br></br>
        <h2>
          <b>Star_Is</b>
        </h2>
      </button>
      <hr></hr>
    </>
  );
};
export default Header;
